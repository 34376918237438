import React, { ReactNode } from "react";
import "../styles/components/Button.css"; // Import the CSS file for hover styles

interface ButtonProps {
  type?: "transparent";
  color?: "white" | "black"; // Light color on dark background
  onClick?: () => void;
  children: ReactNode;
  style?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  type = "transparent",
  color = "white", // Light color on dark background
  onClick = () => null,
  children,
  style,
}) => {
  return (
    <button
      style={style}
      className={`button-${type} button-${color}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
