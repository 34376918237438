import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import "../styles/components/Modal.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const root = document.getElementById("root");

  if (!isOpen || !root) return null;

  return ReactDOM.createPortal(
    <div className="modal-wrapper">
      <div
        className="modal-overlay"
        onClick={onClose}
        role="presentation"
      ></div>
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>,
    root,
  );
};

export default Modal;
