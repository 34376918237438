import { useState, useEffect, useRef } from "react";
import Button from "./Button";
import "../styles/components/Dropdown.css";

interface DropdownProps {
  options: string[];
  onSelect: (option: string) => void;
  defaultValue: string;
}

const Dropdown = ({ options, onSelect, defaultValue }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref to track the dropdown

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: string) => {
    onSelect(option);
    setIsOpen(false); // Close the dropdown when an option is selected
  };

  // Click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    // Add the event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <Button type="transparent" color="white" onClick={handleToggle}>
        {defaultValue}
      </Button>

      <div className={`dropdown-menu ${isOpen ? "show" : ""}`}>
        {options.map((option, index) => (
          <button
            key={index}
            className="dropdown-item"
            onClick={() => {
              handleSelect(option);
            }}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
